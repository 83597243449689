    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding: $spacing-9 0;

    &:not(.categoryContainer) {
        @include not-desktop {
            padding-top: $spacing-5;
        }
    }
}

.loadMore {
    display: flex;
    margin: $spacing-8 0;
    min-width: 8.25rem;
}
