    @import "styles/variables";
    @import "styles/mixins";

.contentbox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include mobile-only {
        flex-direction: column;
    }
}

.listWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @include mobile-only {
        display: none;
    }
}

.column {
    margin: 0 20px 0 0;
    width: 100%;
    color: $color-base-500;

    h3 {
        font-size: 16px;
        margin-bottom: 10px;
        color: $color-base-500;
    }

    .list {
        list-style: none;
        padding: 0;
    }
}

.link {
    color: $color-base-500;
    text-decoration: none;
    display: block;
    font-weight: $font-weight-regular;

    &.dropdown {
        &:not(:last-child) {
            margin-bottom: $spacing-3;
        }
    }
}

.icon {
    $size: 18px;

    width: $size;
    height: $size;
    color: $color-base-500;
}

.paymentAndSocials {
    @include mobile-only {
        flex-direction: column-reverse;
    }
}

.divider {
    @include full-width;

    border: 0;
    border-bottom: 1px solid $color-borders;
    margin-top: $spacing-3;

    @include tablet-up {
        display: none;
    }
}

.dropdownWrapper {
    @include full-width;

    margin-top: $spacing-3;

    @include tablet-up {
        display: none;
    }
}

.dropdownButton {
    justify-content: flex-start;
    padding: $spacing-2 $spacing-4;
    border-bottom: 1px solid $color-borders;
    color: $color-base-500;
}

.dropdownItems {
    position: static !important;
    padding: $spacing-2 $spacing-2 0;
}

.dropdown {
    border: 0 !important;
}
