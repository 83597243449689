    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include not-desktop {
        display: none;
    }
}

.mobileWrapper {
    list-style: none;
    padding: 2rem 1.5rem 2.5rem;

    @include desktop-up {
        display: none;
    }
}

.menu {
    @include tablet-up {
        overflow: auto;
        margin: calc(#{$spacing-5} - 1px) 0 0!important;
        padding: 0 $spacing-3 !important;
    }
}

.flag {
    margin-right: $spacing-2;
    font-size: $font-size-xl !important;
}

.link {
    display: block;
    text-decoration: none;
    color: inherit;
    padding: 0.75rem;
    font-size: $font-size-labels;
    border: solid 1px transparent;
    font-weight: $font-weight-medium;

    &.active {
        border-color: #56aaae;
        pointer-events: none;
        cursor: default;
    }
}

.dropdownItem {
    & + & {
        border-top: 0;
    }
}

.dropdownLink {
    padding: $spacing-1 $spacing-3;
}
