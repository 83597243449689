    @import "styles/variables";
    @import "styles/mixins";

.container {
    flex: 1;
    padding: 0;

    @include tablet-only {
        width: 175px;
    }

    @include desktop-up {
        width: 275px;
    }
}

.title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #636363;
}

.inlineForm {
    text-align: left;
    margin-top: $spacing-4;

    @include tablet-up {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacing-2;
    }

    @include desktop-up {
        margin-left: auto;
        margin-right: auto;
        max-width: 520px;
    }
}

.button {
    width: max-content;
    align-self: start;
}

.input {
    border: 2px solid $color-borders !important;
    border-radius: 0 !important;
}

.subscribeForm {
    @include tablet-up {
        margin-bottom: 0 !important;
    }
}

.disclaimer {
    @include text-small;

    margin-top: $spacing-3 + $spacing-half;
    margin-bottom: 0;
}

.disclaimerLink {
    font-weight: $font-weight-medium;
    color: var(--prezly-accent-color);

    &:hover,
    &:focus {
        color: var(--prezly-accent-color-tint);
    }
}
