    @import "styles/variables";
    @import "styles/mixins";

.container {
    margin-top: 40px;
    padding: 0;
    display: block;
    columns: 250px 4;
    column-gap: 40px;
    max-width: 80vw;
    column-fill: balance;

    @media (width <= 1024px) {
        display: none;
    }

    .column {
        min-width: calc(80% - 4px);
        background-color: #fff;
        display: table;
        position: relative;
        box-sizing: border-box;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 10px;
        color: $color-base-500;
    }

    ul {
        list-style: none;
        padding: 0;
        vertical-align: baseline;

        + h3 {
            margin-top: 20px;
        }
    }

    a {
        text-decoration: none;
        color: $color-base-500;
    }
}
