    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include full-width;

    background-color: #f0f0e7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    margin-bottom: $spacing-5;

    @include mobile-only {
        padding: 0;
    }

    .contentBox {
        &::before,
        &::after {
            content: "";
            display: table;
        }
    }

    ul {
        display: flex;
        justify-content: center;

        @include mobile-only {
            flex-direction: column;
            width: 100vw;
            align-items: flex-start;
            padding: 0;
        }
    }

    li {
        list-style: none;
        margin: 0 25px;
        padding-left: 30px;
        position: relative;

        &::before {
            content: "\f11e";
            display: block;
            color: #56aaae;
            position: absolute;
            /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
            font-family: iconfont;
            font-size: 24px;
            line-height: 24px;
            left: 0;
            top: 18px;
        }
    }

    h3 {
        font-size: 16px;
        cursor: pointer;
    }
}

.tooltipContainer {
    max-width: 200px;
    min-width: 200px;
    position: relative;
    left: -25%;
    margin: 0;
}

.tooltipBody {
    box-sizing: content-box;
    margin: 0;
    background-color: #f0f0e7;
    width: 90%;
    height: auto;
    padding: 15px;
    text-align: left;
    box-shadow: 0 0 5px #c4c4c4;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
    line-height: 1.5em;

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        overflow: hidden;
        width: 0;
        height: 0;
        color: #f0f0e7;
        border-top: 13px solid #f0f0e7;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
    }
}
