    @import "styles/variables";
    @import "styles/mixins";

.box {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    h3 {
        font-size: 16px;
        margin-bottom: 10px;
        color: $color-base-500;
    }

    a {
        color: $color-base-500;
        text-decoration: none;
    }

    .list {
        list-style: none;
        padding: 0;
    }

    .icons {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .paymentbox {
        display: flex;
        place-content: flex-end flex-start;
        align-items: flex-start;
        flex-basis: 100%;
        flex-flow: wrap;
        padding: 0;

        li {
            height: 35px;
            overflow: hidden;
            position: relative;
            margin-bottom: 10px;
            margin-right: 12px;
        }

        img {
            height: 100%;
        }
    }
}
