    @import "styles/variables";
    @import "styles/mixins";

.mobileCategory {
    @include desktop-up {
        display: none !important;
    }
}

.desktopCategories {
    @include not-desktop {
        display: none !important;
    }
}
