    @import "styles/variables";
    @import "styles/mixins";

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    * {
        -moz-osx-font-smoothing: grayscale;
    }
}

.content {
    @include container;

    display: flex;
    flex-direction: column;
    flex: 1;
}

.blur {
    filter: blur(2px);
}
