    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $color-borders;
    width: 100%;
    justify-content: center;
    margin-top: $spacing-7;

    @include mobile-only {
        @include full-width;
    }
}

.link {
    text-decoration: none;
    color: $color-base-500;
    margin: $spacing-3 0;
    padding: 0 $spacing-3;
    border-right: 1px solid $color-borders;

    &:hover {
        text-decoration: underline;
    }
}

.copy {
    color: $color-base-500;
    margin: $spacing-3 0;
    padding: 0 $spacing-3;
}
