    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include text-small;

    padding: $spacing-9 0;
    color: $color-base-500;
    background: $color-base-white;
    border-top: 1px solid $color-borders;
}

.columns {
    display: block;

    @include desktop-up {
        display: flex;
        flex-wrap: wrap;
    }
}

.heading {
    @include heading-3;

    margin: $spacing-3 0;
    color: $color-base-800;
}

.aboutUs {
    @include text-small;

    flex: 1;
    flex-grow: 2;
    margin-bottom: $spacing-6;
    font-size: $font-size-labels;

    @include desktop-up {
        margin-bottom: 0;

        + .contacts {
            margin-left: $spacing-6;
        }
    }
}

.contacts {
    @include text-small;

    flex: 1;
    color: $color-base-500;
    font-size: $font-size-labels;

    @include tablet-up {
        min-width: 350px;
    }
}

.about {
    margin-bottom: $spacing-5;
    color: $color-base-500;
    font-size: $font-size-labels;

    * {
        color: inherit;
    }
}

.socialMedia {
    margin-bottom: $spacing-3;
    color: $color-base-400;

    a:hover {
        color: #56aaae;
    }
}

.link {
    @include link-black;
}

.separator {
    width: 1.5rem;
    height: 0.125rem;
    background: $color-base-800;
}
